import React, { Suspense, Fragment, useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, Outlet } from 'react-router-dom';
import Logo from '../../assets/img/logos/main-logo.png';
import { FaInstagram } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
import { RiTiktokLine } from "react-icons/ri";
import { MdMenu, MdMenuOpen } from "react-icons/md";
import Footer from '../../components/footer/footer.component';

import './navigation.styles.scss';

const Navigation = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const secondaryNavRef = useRef(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (secondaryNavRef.current && !secondaryNavRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const handleResize = () => {
    if (window.innerWidth > 1024) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, [showMenu]);

  return (
    <Suspense fallback={<div></div>}>
      <Fragment>
        <div>
          <nav className='nav-bar__container'>
            <div className='nav-bar__logo-container'>
              <NavLink to="/">
                <img className='nav-bar__logo' src={Logo} alt='Nav bar logo' />
              </NavLink>
            </div>
            <div className={`nav-bar__menu-toggle ${showMenu ? 'open-nav-bar' : ''}`} onClick={toggleMenu}>
              {showMenu ? <MdMenuOpen className='nav-bar__menu-toogle-icon' /> : <MdMenu className='nav-bar__menu-toogle-icon' />}
            </div>
            <div className='nav-bar__page-links-container'>
              <NavLink className='nav-bar__page-link nav-link' to="/" activeclassname="active" isActive={() => location.pathname === '/'}>
                Home
              </NavLink>
              <NavLink className='nav-bar__page-link nav-link' to="/about-us" activeclassname="active" isActive={() => location.pathname.startsWith('/about-us')}>
                Porcelain Veneers
              </NavLink>
              <NavLink className='nav-bar__page-link nav-link' to="/contact-us" activeclassname="active" isActive={() => location.pathname.startsWith('/contact-us')}>
                Contact Us
              </NavLink>
            </div>
            <div className='nav-bar__social-links-container'>
              <NavLink className='nav-bar__social-link nav-link' target="_blank" to="https://www.instagram.com/smileon_colombia/?igsh=MXVlcHl2cDI4YWkwaQ%3D%3D">
                <FaInstagram />
              </NavLink>
              <NavLink className='nav-bar__social-link nav-link' target="_blank">
                <AiOutlineYoutube />
              </NavLink>
              <NavLink className='nav-bar__social-link nav-link' target="_blank" to="">
                <RiTiktokLine />
              </NavLink>
            </div>
          </nav>
          {showMenu && (
            <div className='secondary-nav__container' ref={secondaryNavRef}>
              <div className='nav-bar__page-links-container open-nav-bar'>
                <NavLink className='nav-bar__page-link nav-link' to="/" activeclassname="active">
                  Home
                </NavLink>
                <NavLink className='nav-bar__page-link nav-link' to="/about-us" activeclassname="active">
                  Porcelain Veneers
                </NavLink>
                <NavLink className='nav-bar__page-link nav-link' to="/contact-us" activeClassName="active">
                  Contact Us
                </NavLink>
              </div>
              <div className='nav-bar__social-links-container open-nav-bar'>
                <NavLink className='nav-bar__social-link nav-link' target="_blank" to="https://www.instagram.com/smileon_colombia/?igsh=MXVlcHl2cDI4YWkwaQ%3D%3D">
                  <FaInstagram />
                </NavLink>
                <NavLink className='nav-bar__social-link nav-link' target="_blank">
                  <AiOutlineYoutube />
                </NavLink>
                <NavLink className='nav-bar__social-link nav-link' target="_blank" to="">
                  <RiTiktokLine />
                </NavLink>
              </div>
            </div>
          )}
          <Outlet />
          <Footer />
        </div>
      </Fragment>
    </Suspense>
  );
};

export default Navigation;
