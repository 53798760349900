import Logo from '../../assets/img/logos/main-logo.png';
import { NavLink } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";
import { RiTiktokLine } from "react-icons/ri";
import { MdOutlineEmail } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";

import FooterInfoItem from './footer-info.component';

import './footer.styles.scss';

const Footer = () => {

  const footerFollowItems = [
    {
      icon: FaInstagram,
      text: 'Instagram',
      path: 'https://www.instagram.com/smileon_colombia/?igsh=MXVlcHl2cDI4YWkwaQ%3D%3D'
    },
    {
      icon: RiTiktokLine,
      text: 'Tik tok',
      path: ''
    },
  ]

  const footerContactUs = [
    { 
      icon: MdOutlineEmail,
      text: 'contact@smileoncolombia.com.co',
      path: ''
    },
    { 
      icon: SlLocationPin,
      text: "Medellín, Antioquia, Colombia",
      path: 'https://www.google.com/maps/place/Medell%C3%ADn,+Antioquia/'
    },
  ]

  return (
    <div className='footer-container'>
      <div className='footer__main-section'>
        <FooterInfoItem title='Follow Us' configData={footerFollowItems} />
        <NavLink className='footer__logo-container' to='/' end>
          <img className='footer__logo' src={Logo} alt='Footer Smile on Colombia logo' />
        </NavLink>
        <FooterInfoItem title='Contact' configData={footerContactUs} className='footer__info-contact'/>
      </div>
      <div className='footer-copyright'>
        <p>&copy; Copyright - Smile On Colombia 2024</p>
      </div>
    </div>
  )
}

export default Footer;