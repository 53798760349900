import React, { Suspense } from 'react';
import InstagramFloatingButton from '../../components/InstagramFloatingButton/instagram-floating-btn.component';

import { useNavigate } from 'react-router-dom';
const HeroSection = React.lazy(() => import('../../components/heroSection/hero-section.component'));
const VeneersSteps = React.lazy(() => import ('../../components/veneers-steps/veneers-steps.component'));
const Faq = React.lazy(() => import ('../../components/faq/faq.component'));
const Newsletter = React.lazy(() => import ('../../components/newsletter/newsletter.component'));

const AboutUs = () => {

  const navigate = useNavigate();

  const handleContactUsClick = () => {
    navigate("/", { state: { scrollToOnlineConsultation: true } });
  };

  const headerInfo = {
    title: "At SMILE ON COLOMBIA",
    firstParagraph: "We specialize in transforming smiles through our custom Porcelain Veneers service. Our clinic is dedicated to enhancing your smile with personalized care and attention to detail.",
    secondParagraph: "We understand the importance of a beautiful and harmonious smile, which is why we offer Porcelain Veneers tailored to each patient's individual needs.",
    backgroundUrl: 'office',
    backgroundGradient: ['(16, 73, 67, 0)', '(16, 73, 67, 1)']
  }
  return (
    <Suspense>
      <div className='about-us-container'>
        <HeroSection headerInfo={headerInfo} onContactUsClick={handleContactUsClick}/>
        <VeneersSteps />
        <Faq />
        <Newsletter />
        <InstagramFloatingButton />
      </div>
    </Suspense>
  )
}

export default AboutUs;