import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import './instagram-floating-btn.styles.scss';

const InstagramFloatingButton = () => {
  return (
    <a
      href="https://www.instagram.com/smileon_colombia"
      target="_blank"
      rel="noopener noreferrer"
      className="instagram-floating-button"
    >
      <FaInstagram className="instagram-icon" />
    </a>
  );
};

export default InstagramFloatingButton;